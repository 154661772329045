import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import EmailCapture from "../components/emailCatpure"
// import EmailRedirect from "../components/emailFormRedirect"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />

    {/* HERO */}
    <div className="hero1">
      <div className="content taCenter">
        <h1>Contact Us</h1>
        <p>
          Hey there{" "}
          <span role="img" aria-label="hand-waving">
            👋
          </span>{" "}
          let's Chat.
        </p>
      </div>
    </div>

    {/* BODY */}
    <div className="hero3">
      <div className="content taLeft" style={{ maxWidth: "740px" }}>
        {/* <div className="taCenter">
          <h2>Here's how you can reach us:</h2>
        </div> */}
        <h2>Email</h2>
        <p>
          <a href="mailto:hello@vantagesms.com">hello@vantagesms.com</a>
          <br />
          <a href="mailto:support@vantagesms.com">support@vantagesms.com</a>
        </p>
        <hr />
        <h2>Phone</h2>
        <p>+1-855-908-4011</p>
        <hr />
        <h2>Address</h2>
        <p>7030 Woodbine Avenue, Suite 500, Markham, L3R 6G2</p>
        <hr />
        <h2>Map</h2>
        <p>
          <iframe
            className="mapsFrame"
            title="googleMapsLocation"
            src={
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46064.059570301295!2d-79.35252868415621!3d43.81428217899552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d36ee2657485%3A0x473aca268f70cc47!2s7030+Woodbine+Ave%2C+Markham%2C+ON+L3R+6G2!5e0!3m2!1sen!2sca!4v1539297366869"
            }
            width="100%"
            height="450"
          />
        </p>
        {/* <hr /> */}
      </div>
    </div>

    {/* HERO FOOTER*/}
    <div className="heroFooter taCenter">
      <div className="content">
        <h1>Grow Your Sales</h1>
        {/* <p>
          Share your contact lists. Collaborate on message. We take care of the
          rest!
        </p> */}
        <p>Reach the customers that matter most, your own.</p>
        <EmailCapture />
        {/* <p style={{ padding: "0px 20px" }}>
          <input type="email" name="" placeholder="your.name@email.com" />
          <br />
          <button className="">Get Started</button>
          <button className="">Yes, Contact Me</button>
          <button className="">Yes, Contact Me to Get Started</button>
        </p> */}
      </div>
    </div>
  </Layout>
)

export default ContactPage
